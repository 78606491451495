<template>
    <div >
        <div class="container-fluid qsuite-c-fluid-0">
            <div class="col-12">
                <div class="row">
                    <div class="col-auto align-self-center text-right pr-0">
                        <b-link :to="{name: 'Dashboard'}">
                            <a class="btn  rounded-circle btn-circle font-20 card-body-list-cta" style="background:var(--color-broker);"  href="#"><i class="fas fa-arrow-left"></i></a>
                        </b-link>
                    
                    </div>
                    <div class="col-10 align-self-center">
                        <h3 class="page-title text-truncate text-dark font-weight-medium mb-1">perfil</h3>
                        <div class="d-flex align-items-center">
                            <nav aria-label="breadcrumb">
                                <ol class="breadcrumb m-0 p-0">
                                    <li class="breadcrumb-item"><b-link to="/dashboard">dashboard / perfil</b-link></li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12">
                <div class="row">
                    <div class="col-12">
                        <div class="card card-bg-trans">
                            <div class="card-body pt-4 px-0">
                                <b-tabs>
                                    <form class="px-2 needs-validation" novalidate @submit.prevent="save" enctype="multipart/form-data">
    
                                        <b-tab class="tab-pane" id="dadospessoais">
                                            <template #title>
                                                <div class="d-flex">
                                                    <i class="far fa-list-alt font-20 align-middle mr-2"></i><span class="d-none d-lg-block"> Dados pessoais <b-badge variant="danger" v-show="error_information">1</b-badge></span>
                                                </div>
                                            </template>
    
                                            <div class="tab-content">
                                <div class="tab-pane show active" id="dadospessoais">
                                  
                                        <div class="form-body">
                                            <div class="row">
                                                <div class="col-12 col-md-6 col-lg-3">
                                                    <div class="form-group">
                                                        <label>Código do corretor</label>
                                                        <input type="text" v-model="broker.id" class="form-control" placeholder="#5898" disabled="">
                                                    </div>
                                                </div>
                                                <div class="col-12 col-md-6 col-lg-3">
                                                    <div class="form-group">
                                                        <label>CRECI</label>
                                                        <input type="text" disabled v-model="broker.creci" class="form-control" placeholder="345898">
                                                    </div>
                                                </div>
                                                <div class="col-12 col-md-6 col-lg-3">
                                                    <div class="form-group">
                                                        <label>Imobiliária</label>
                                                        <input type="text" v-model="broker.real_state" class="form-control" placeholder="Adão Imóveis">
                                                    </div>
                                                </div>
                                                <div class="col-12 col-md-6 col-lg-3">
                                                    <div class="form-group">
                                                        <label>Corretor desde </label>
                                                        <input v-model="broker.broker_at" type="date" class="form-control" placeholder="21/01/2021">
                                                    </div>
                                                </div>
                                                <div class="col-12 col-md-6 col-lg-3">
                                                    <div class="form-group">
                                                        <label>Data cadastro </label>
                                                        <input v-model="broker.created_at" type="date" class="form-control" placeholder="21/01/2021" disabled="">
                                                    </div>
                                                </div>
                                                <div class="col-12 col-md-6 col-lg-3">
                                                    <div class="form-group">
                                                        <label>Como conheceu a empresa? </label>
                                                        <select v-model="broker.source" class="custom-select mr-sm-2" id="inlineFormCustomSelect">
                                                            <option >Selecione</option>
                                                            <option value="GOOGLE">Google</option>
                                                            <option value="SOCIAL_MEDIA">Rede Social</option>
                                                            <option value="RADIO">Rádio</option>
                                                            <option value="OUTDOOR">Outdoor</option>
                                                            <option value="MAGAZINE">Revista</option>
                                                            <option value="INDICATION">Indicação</option>
                                                            <option value="OTHER">Outros</option>
                                                        </select>
                                                        <small id="name1" style="background:var(--color-broker);"  class="badge badge-default badge-info form-text text-white float-left">Definir a origem do lead</small>
                                                    </div>
                                                </div>
                                                <div class="col-12 pt-4"></div>
                                                <div class="col-12 col-md-6 col-lg-5">
                                                    <div class="form-group">
                                                        <label>Nome </label>
                                                        <input disabled v-model="broker.first_name" type="text" class="form-control" placeholder="">
                                                        <div style="background:var(--color-broker);" class="invalid-feedback pl-4 pb-3">Por favor! Informe o seu nome.</div>
                                                    </div>
                                                </div>
                                                <div class="col-12 col-md-6 col-lg-4">
                                                    <div class="form-group">
                                                        <label>Sobrenome </label>
                                                        <input disabled v-model="broker.last_name"  type="text" class="form-control" placeholder="">
                                                        <div  style="background:var(--color-broker);"  class="invalid-feedback pl-4 pb-3">Por favor! Informe o seu sobrenome.</div>
                                                    </div>
                                                </div>
                                                <div class="col-12 col-md-6 col-lg-3">
                                                    <div class="form-group">
                                                        <label>CPF </label>
                                                        <input disabled  v-model="broker.cpf" @blur="verifyDataAvailable('Cpf')"  v-mask="'###.###.###-##'" type="tel" class="form-control" value="1-(444)-444-4445">
                                                    </div>
                                                </div>
                                                <div class="col-12 col-md-6 col-lg-3">
                                                    <div class="form-group">
                                                            <label>Sua foto</label>
                                                            <div class="input-group mb-3">
                                                                <div class="input-group-prepend w-100">
                                                                    <span  style="background:var(--color-broker);" class="input-group-text">Upload</span>
                                                                    <div class="custom-file">
                                                                        <input required type="file" multiple class="custom-file-input" @change="setFile" id="inputGroupFile01">
                                                                        <!-- <label class="custom-file-label" for="inputGroupFile01">{{broker.image.name}}</label> -->
                                                                    </div>   
                                                                </div> 
                                                            </div>                                                
                                                        
                                                    </div>                                                 
                                                </div>
                                                <div class="col-12 col-md-6 col-lg-3">
                                                    <div class="form-group">
                                                        <label>Data de nascimento </label>
                                                        <input disabled v-model="broker.birth_date" type="date" class="form-control" placeholder="">
                                                    </div>
                                                </div>
                                                <div class="col-12 col-md-6 col-lg-3">
                                                    <div class="form-group">
                                                        <label>Sexo </label>
                                                        <select v-model="broker.sex" class="custom-select mr-sm-2" id="inlineFormCustomSelect">
                                                            <option >Selecione</option>
                                                            <option >Masculino</option>
                                                            <option >Feminino</option>
                                                            <option >Não quero informar</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div class="col-12 col-md-6 col-lg-3">
                                                    <div class="form-group">
                                                        <label>Estado civil </label>
                                                        <select v-model="broker.marital_status" class="custom-select mr-sm-2" id="inlineFormCustomSelect">
                                                            <option >Selecione</option>
                                                            <option >Solteiro(a)</option>
                                                            <option >Casado(a) Comunhão de Bens</option>
                                                            <option >Casado(a) Comunhão Parcial de Bens</option>
                                                            <option >Casado(a) Separação de Bens</option>
                                                            <option >Divorciado(a)</option>
                                                            <option >Separado(a) Judicialmente</option>
                                                            <option >Viúvo(a)</option>
                                                            <option >União Estável/Outros</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div class="col-12 col-md-6 col-lg-3">
                                                    <div class="form-group">
                                                        <label>Grau de instrução </label>
                                                        <select v-model="broker.education_level" class="custom-select mr-sm-2" id="inlineFormCustomSelect">
                                                            <option >Selecione</option>
                                                            <option >Não Alfabetizado</option>
                                                            <option >Ensino Fundamental Incompleto</option>
                                                            <option >Ensino Fundamental Completo</option>
                                                            <option >Médio Incompleto</option>
                                                            <option >Médio Completo</option>
                                                            <option >Superior Incomplet</option>
                                                            <option >Superior Completo</option>
                                                            <option >Especialização2</option>
                                                            <option >Mestrado</option>
                                                            <option >Doutorado</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div class="col-12 col-md-6 col-lg-3">
                                                    <div class="form-group">
                                                        <label>Nacionalidade </label>
                                                        <select  v-model="broker.nacionality" class="custom-select mr-sm-2" id="inlineFormCustomSelect">
                                                            <option >Selecione</option>
                                                            <option >Brasileira</option>
                                                            <option >Estrangeira</option>
                                                            <option >Brasileira Naturalizado</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div class="col-12 col-md-6 col-lg-6">
                                                            <div class="form-group">
                                                                <label>Naturalidade </label>
                                                                <div class="row">
                                                                    <div class="col-12 col-md-6">
                                                                        <select class="custom-select mr-sm-2" name="uf" v-model="broker.born_state">
                                                                            <option :value="null">Selecione</option>
                                                                            <option v-for="item in states" :key="item.sigla" :value="item.sigla" >{{ item.nome }}</option>
                                                                        </select>
                                                                    </div>
                                                                    <div class="col-12 col-md-6">
                                                                        <select class="custom-select mr-sm-2" v-model="broker.born_city">
                                                                            <option :value="null">Selecione</option>
                                                                            <option v-for="item in getCities(broker.born_state)" :key="item" :value="item">{{ item }}</option>
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                                
                                                            </div>
                                                        </div>
                                                <div class="col-12"></div>
                                                <div class="col-12 col-md-6 col-lg-6">
                                                    <div class="form-group">
                                                        <label>Nome do pai </label>
                                                        <input  v-model="broker.father_name"  type="text" class="form-control" placeholder="">
                                                    </div>
                                                </div>
                                                <div class="col-12 col-md-6 col-lg-6">
                                                    <div class="form-group">
                                                        <label>Nome do mãe </label>
                                                        <input  v-model="broker.mother_name" type="text" class="form-control" placeholder="">
                                                    </div>
                                                </div>
                                                <div class="col-12"></div>
                                                <div class="col-12 col-md-6 col-lg-3">
                                                    <div class="form-group">
                                                        <label>Número da identidade </label>
                                                        <input v-model="broker.rg" type="tel" class="form-control" placeholder="">
                                                    </div>
                                                </div>
                                                <div class="col-12 col-md-6 col-lg-3">
                                                    <div class="form-group">
                                                        <label>Órgão emissor </label>
                                                        <input v-model="broker.rg_emissor" type="text" class="form-control" placeholder="">
                                                    </div>
                                                </div>
                                                <div class="col-12 col-md-6 col-lg-3">
                                                    <div class="form-group">
                                                        <label>UF </label>
                                                        <select v-model="broker.rg_uf" class="custom-select mr-sm-2" id="inlineFormCustomSelect">
                                                            <option :value="null">Selecione</option>
                                                             <option v-for="item in states" :key="item.sigla" :value="item.sigla" >{{ item.nome }}</option>
                                                           
                                                        </select>
                                                    </div>
                                                </div>
                                                <div class="col-12 col-md-6 col-lg-3">
                                                    <div class="form-group">
                                                        <label>Data de emissão </label>
                                                        <input v-model="broker.rg_date" type="date" class="form-control" placeholder="">
                                                    </div>
                                                </div>
                                                <div class="col-12"></div>
                                                <br>
                                                <div class="col-12 py-2">
                                                    <h4 class="card-title">Contatos</h4>
                                                </div>
                                                <div class="col-12 col-md-6 col-lg-4">
                                                    <div class="form-group">
                                                        <label>E-mail </label>
                                                        <input @blur="verifyDataAvailable('Email')" v-model="broker.email" type="email" class="form-control" value="abc@example.com">
                                                    </div>
                                                </div>
                                                <div class="col-12 col-md-6 col-lg-4">
                                                    <div class="form-group">
                                                        <label>Telefone </label>
                                                        <div class="d-flex">
                                                            <AreaCode :defaultCountry="broker.telephone_area_code_iso" @setIsoCode="setTelephoneIsoCode" @setAreaCode="setTelephoneAreaCode"/>
                                                            <input type="tel" class="form-control" placeholder="(44) 4 4444-4444" v-model="broker.telephone" v-mask="['(##) ####-####', '(##) # ####-####']" >
                                                        </div>
                                                        
                                                    </div>
                                                </div>
                                                <div class="col-12 col-md-6 col-lg-4">
                                                    <div class="form-group">
                                                        <label>Whatsapp </label>
                                                        <div class="d-flex">
                                                            <AreaCode :defaultCountry="broker.whatsapp_area_code_iso"  @setIsoCode="setWhatsAppIsoCode" @setAreaCode="setWhatsAppAreaCode"/>
                                                            <input  @blur="verifyDataAvailable('WhatsApp')" type="tel" class="form-control" placeholder="(44) 4 4444-4444" v-model="broker.whatsapp" v-mask="['(##) ####-####', '(##) # ####-####']" >
                                                        </div>
                                                        
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    
                                </div>
                                <div class="tab-pane" id="redessociais">
                                    <form action="" class="px-2 needs-validation" novalidate="">
                                        <div class="form-body">
                                            <div class="row">
                                                <div class="col-12 col-md-6 col-lg-6 py-2">
                                                    <div class="input-group">
                                                        <div class="input-group-prepend">
                                                            <div class="input-group-text" style="background:var(--color-broker);" id="btnGroupAddon2"><i class="fab fa-facebook"></i></div>
                                                        </div>
                                                        <input type="text" class="form-control" placeholder="" aria-label="Input group example" aria-describedby="btnGroupAddon2">
                                                    </div>
                                                </div>
                                                <div class="col-12 col-md-6 col-lg-6 py-2">
                                                    <div class="input-group">
                                                        <div class="input-group-prepend">
                                                            <div class="input-group-text" style="background:var(--color-broker);" id="btnGroupAddon2"><i class="fab fa-instagram"></i></div>
                                                        </div>
                                                        <input type="text" class="form-control" placeholder="" aria-label="Input group example" aria-describedby="btnGroupAddon2">
                                                    </div>
                                                </div>
                                                <div class="col-12 col-md-6 col-lg-6 py-2">
                                                    <div class="input-group">
                                                        <div class="input-group-prepend">
                                                            <div class="input-group-text" style="background:var(--color-broker);" id="btnGroupAddon2"><i class="fab fa-linkedin-in"></i></div>
                                                        </div>
                                                        <input type="text" class="form-control" placeholder="" aria-label="Input group example" aria-describedby="btnGroupAddon2">
                                                    </div>
                                                </div>
                                                <div class="col-12 col-md-6 col-lg-6 py-2">
                                                    <div class="input-group">
                                                        <div class="input-group-prepend">
                                                            <div class="input-group-text" style="background:var(--color-broker);" id="btnGroupAddon2"><i class="fab fa-twitter"></i></div>
                                                        </div>
                                                        <input type="text" class="form-control" placeholder="" aria-label="Input group example" aria-describedby="btnGroupAddon2">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-actions">
                                            <div class="text-right">
                                                <button type="reset" class="btn btn-dark">Cancelar</button>
                                                <button type="submit" style="background:var(--color-broker);"  class="btn">Cadastrar</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                               
                                <div class="tab-pane" id="configuracoes">
                
                                        <div class="form-body">
                                            <div class="row">
                                                <div class="col-12 col-md-6 col-lg-4">
                                                    <div class="form-group">
                                                        <label>Status do cadastro </label>
                                                        <select v-model="broker.is_active" class="custom-select mr-sm-2" id="inlineFormCustomSelect">
                                                            <option :value="true">Ativo</option>
                                                            <option :value="false">Inativo</option>
                                                        </select>
                                                        <small id="name13" class="badge badge-default badge-danger form-text text-white">Desativar o acesso do corretor na plataforma</small>
                                                    </div>
                                                </div>
                                                <div  class="col-12 pt-4 pb-2">
                                                    <h4 class="card-title">Senha de acesso</h4>
                                                </div>
                                                <div class="col-12 col-md-6 col-lg-4">
                                                    <div class="form-group">
                                                        <label>Resetar senha </label>
                                                        <input v-model="broker.password" type="password" class="form-control" id="passtext" placeholder="********">
                                                    </div>
                                                </div>
                                                <div class="col-12 col-md-6 col-lg-4">
                                                    <div class="form-group">
                                                        <label>Confirmar senha </label>
                                                        <input v-model="broker.password_confirmation" type="password" class="form-control" id="passtext" placeholder="********">
                                                    </div>
                                                    <small id="name1" class="badge badge-default badge-info form-text text-white float-left" style="background:var(--color-broker);">Deve ter entre 8 e 20 caracteres.</small>
                                                </div>
                                                <div class="col-12">
                                                    <div class="form-group">
                                                        <div class="col-12 py-2">
                                                            <i class="fab fa-facebook font-20 align-middle"></i> Rafael Dias
                                                        </div>
                                                        <button type="submit" class="btn btn-danger">Desvincular conta <i class="pl-1 far fa-trash-alt"></i></button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                      
                                </div>
                            </div> 
                                    </b-tab>
    

    
                                    <b-tab class="tab-pane" id="enderecoresidencial">
                                        <template #title>
                                            <div class="d-flex">
                                                <i class="far fa-map font-20 align-middle mr-2"></i><span class="d-none d-lg-block"> Endereço residencial <b-badge variant="danger" v-show="address_error">1</b-badge></span>
                                            </div>
                                        </template>

                                        <div class="tab-pane" id="enderecoresidencial">
                                            <form action="" class="px-2 needs-validation" novalidate="">
                                                <div class="form-body">
                                                    <div class="row">
                                                        <div class="col-12 col-md-4 col-lg-3">
                                                            <div class="form-group">
                                                                <label>CEP </label>
                                                                <input v-model="broker.cep" @blur="getAddress()"  v-mask="'#####-###'" type="tel" class="form-control" value="74.830-50">
                                                            </div>
                                                        </div>
                                                        <div class="col-12"></div>
                                                        <div class="col-12 col-md-6 col-lg-4">
                                                            <div class="form-group">
                                                                <label>Rua/Avenida/Alameda/Travessa </label>
                                                                <input v-model="broker.street"  type="text" class="form-control" placeholder="">
                                                            </div>
                                                        </div>
                                                        <div class="col-12 col-md-6 col-lg-2">
                                                            <div class="form-group">
                                                                <label>Número </label>
                                                                <input v-model="broker.number"  type="text" class="form-control" placeholder="">
                                                            </div>
                                                        </div>
                                                        <div class="col-12 col-md-6 col-lg-6">
                                                            <div class="form-group">
                                                                <label>Complemento </label>
                                                                <input v-model="broker.complement" type="text" class="form-control" placeholder="">
                                                            </div>
                                                        </div>
                                                        <div class="col-12 col-md-6 col-lg-6">
                                                            <div class="form-group">
                                                                <label>Bairro </label>
                                                                <input v-model="broker.neighborhood"  type="text" class="form-control" placeholder="">
                                                            </div>
                                                        </div>
                                                        <div class="col-12 col-md-6 col-lg-2">
                                                            <div class="form-group">
                                                                <label>UF </label>
                                                                <select v-model="broker.state" class="custom-select mr-sm-2" id="inlineFormCustomSelect">
                                                                    <option :value="null">Selecione</option>
                                                                     <option v-for="item in states" :key="item.sigla" :value="item.sigla" >{{ item.nome }}</option>
                                                           
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div class="col-12 col-md-6 col-lg-4">
                                                            <div class="form-group">
                                                                <label>Município </label>
                                                                <select v-model="broker.city"  class="custom-select mr-sm-2" id="inlineFormCustomSelect">
                                                                    <option :value="null">Selecione</option>
                                                                            <option v-for="item in getCities(broker.state)" :key="item" :value="item">{{ item }}</option>
                                                           
                                                                </select>
                                                            </div>
                                                        </div>
                                                        
                                                        <div class="col-12 col-md-6 col-lg-3">
                                                            <div class="form-group">
                                                                <label>Sua residência é </label>
                                                                <select  v-model="broker.residence_type" class="custom-select mr-sm-2" id="inlineFormCustomSelect">
                                                                    <option :value="null">Selecione</option>
                                                                    <option value="LEASED">Alugada</option>
                                                                    <option value="PARENTS">Mora com parentes/pais</option>
                                                                    <option value="GIVEN">Cedida/Funcional</option>
                                                                    <option value="OWN">Própria quitada</option>
                                                                    <option value="FINANCED_CAIXA">Financiada pela CAIXA</option>
                                                                    <option value="FINANCED">Financiada por outras entidades</option>
                                                                    <option value="OTHERS">Outros</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            
                                            </form>
                                        </div>
                                                
                                    </b-tab>

                              
                                    <b-tab class="tab-pane" id="imoveis">
                                        <template #title>
                                            <div class="d-flex">
                                                <i class="fas fa-globe font-20 align-middle mr-2"></i><span class="d-none d-lg-block"> Rede sociais <b-badge variant="danger" v-show="enterprise_error">1</b-badge></span>
                                            </div>
                                        </template>
                                           
                                        <div class="tab-pane" id="redessociais">
                                            <form action="" class="px-2 needs-validation" novalidate="">
                                                <div class="form-body">
                                                    <div class="row">
                                                        <div class="col-12 col-md-6 col-lg-6 py-2">
                                                            <div class="input-group">
                                                                <div class="input-group-prepend">
                                                                    <div class="input-group-text" id="btnGroupAddon2" style="background:var(--color-broker);"><i class="fab fa-facebook"></i></div>
                                                                </div>
                                                                <input v-model="broker.facebook_url" type="text" class="form-control" placeholder="" aria-label="Input group example" aria-describedby="btnGroupAddon2">
                                                            </div>
                                                        </div>
                                                        <div class="col-12 col-md-6 col-lg-6 py-2">
                                                            <div class="input-group">
                                                                <div class="input-group-prepend">
                                                                    <div class="input-group-text" id="btnGroupAddon2" style="background:var(--color-broker);"><i class="fab fa-instagram"></i></div>
                                                                </div>
                                                                <input v-model="broker.instagram_url"  type="text" class="form-control" placeholder="" aria-label="Input group example" aria-describedby="btnGroupAddon2">
                                                            </div>
                                                        </div>
                                                        <div class="col-12 col-md-6 col-lg-6 py-2">
                                                            <div class="input-group">
                                                                <div class="input-group-prepend">
                                                                    <div class="input-group-text" id="btnGroupAddon2" style="background:var(--color-broker) !important;"><i class="fab fa-linkedin-in"></i></div>
                                                                </div>
                                                                <input v-model="broker.linkedin_url" type="text" class="form-control" placeholder="" aria-label="Input group example" aria-describedby="btnGroupAddon2">
                                                            </div>
                                                        </div>
                                                        <div class="col-12 col-md-6 col-lg-6 py-2">
                                                            <div class="input-group">
                                                                <div class="input-group-prepend">
                                                                    <div class="input-group-text" id="btnGroupAddon2" style="background:var(--color-broker) !important;"><i class="fab fa-twitter"></i></div>
                                                                </div>
                                                                <input v-model="broker.twitter_url" type="text" class="form-control" placeholder="" aria-label="Input group example" aria-describedby="btnGroupAddon2">
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                              
                                            </form>
                                        </div>                                         
                                    </b-tab>

                                    <b-tab class="tab-pane" id="imoveis">
                                        <template #title>
                                            <div class="d-flex">
                                                <i class="fas fa-cog font-20 align-middle mr-2"></i><span class="d-none d-lg-block">Configurações <b-badge variant="danger" v-show="enterprise_error">1</b-badge></span>
                                            </div>
                                        </template>                                            
                                        <div class="tab-pane" id="configuracoes">  
                                            <div class="form-body">
                                                <div class="row">
                                                    <div  class="col-12 pt-4 pb-2">
                                                        <h4 class="card-title">Senha de acesso</h4>
                                                    </div>
                                                    <div class="col-12 col-md-6 col-lg-4">
                                                        <div class="form-group">
                                                            <label>Resetar senha </label>
                                                            <input v-model="broker.password" name="password" type="password" class="form-control" id="password" placeholder="********">
                                                        </div>
                                                    </div>
                                                    <div class="col-12 col-md-6 col-lg-4">
                                                        <div class="form-group">
                                                            <label>Confirmar senha </label>
                                                            <input v-model="broker.password_confirmation" type="password" class="form-control" id="passtext" placeholder="********">
                                                        </div>
                                                        <small id="name1" class="badge badge-default badge-info form-text text-white float-left" style="background:var(--color-broker);">Deve ter entre 8 e 20 caracteres.</small>
                                                    </div>                                                                                 
                                                </div>                                           
                                            </div>                                       
                                        </div>
                                    </b-tab>
                             

                                    <div class="form-actions">
                                        <div class="text-right">
                                            <router-link :to="{name: 'Broker'}" class="btn btn-dark">Cancelar</router-link>
                                            <button style="background:var(--color-broker);"   type="submit" class="btn btn-info ml-2">{{ id ? 'Atualizar' : 'Cadastrar' }}</button>
                                        </div>
                                    </div>
                                    </form>
                                </b-tabs>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
         <footer class="footer text-center text-muted p-0">
        <div class="col-12 py-3">
            <div class="col-12">
                <div class="row">
                    <div class="col-12 col-md-9 col-lg-10 text-center text-sm-left align-self-center mx-auto py-2">
                        © {{new Date(Date.now()).getFullYear()}} | QSuíte _Hub de integração de dados. Todos os direitos reservados. <a :href="'https://'+site+'/termos-de-uso'" class="a-link-1" style="text-decoration:none; color:var(--color-broker);">Termos de uso</a> | <a :href="'https://'+site+'/politica-de-privacidade'" class="a-link-1" style="text-decoration:none; color:var(--color-broker);">Política de privacidade</a> | <a href="https://r4marketing.com.br/termos-de-uso" class="a-link-1" style="text-decoration:none; color:var(--color-broker);">Termos de Uso</a>
                    </div>
                    <div class="col-12 col-md-3 col-lg-2 text-center text-sm-right align-self-center">
                        <a href="https://qsuite.com.br" target="_blank"><img src="@/assets/img/logo-gsuite.png" alt="Qsuite" style="max-height: 23px;"></a>
                    </div>
                </div>
            </div>
        </div>
    </footer>
    </div>
    </template>
    <script>
    import BrokerService from "@/services/resources/BrokerService";
    import states from '@/assets/js/states.json'
    import axios from 'axios';
    import AreaCode  from "@/components/AreaCode.vue";
    import VerifyDataAvailableService from "@/services/resources/VerifyDataAvailableService";
    import Cookies from "js-cookie";
    
    const dataAvailable = VerifyDataAvailableService.build();
    const service = BrokerService.build();
    
import ThemeService from "@/services/resources/ThemeService";

const serviceTheme = ThemeService.build();

    export default {
        components: {
            AreaCode
        },
       data(){
           return{
             site:"",
               broker:{
                    "email" : "",
                    "password" : "",
                    "password_confirmation": "",
                    "first_name" : "",
                    "last_name" : "",
                    "cpf" : "",
                    "document" : "",
                    "birth_date" : "",
                    "sex": "Selecione",
                    "marital_status" : "Selecione",
                    "education_level" : "Selecione",
                    "nacionality" : "Selecione",
                    "born_state" : "",
                    "born_city" : "",
                    "image": "",
                    "father_name" : "",
                    "mother_name" : "",
                    "rg" : "",
                    "rg_emissor" : "",
                    "rg_uf" : "",
                    "rg_date" : "",
                    "telephone" : "",
                    "whatsapp" : "",
                    "creci" : "",
                    "real_state" : "",
                    "broker_at" : "",
                    "source" : "Selecione",
                    "cep" : "",
                    "street" : "",
                    "number" : "",
                    "complement" : "",
                    "neighborhood" : "",
                    "city" : "",
                    "state" : "",
                    "is_active" : true,
                    "residence_type" : "",
                    "facebook_url" : "",
                    "instagram_url" : "",
                    "linkedin_url" : "",
                    "twitter_url" : "",
                    "whatsapp_area_code": "55",
                    "whatsapp_area_code_iso": "BR",
                    "telephone_area_code": "55",
                    "telephone_area_code_iso": "BR"
                },
        
               pages:[
                    {
                        name: 'dashboard',
                        to: 'Dashboard'
                    },
                    {
                        name: 'novo corretor',
                        to: 'BrokerNew'
                    }
                ],
                menu:[
                    {
                        name: 'Novo corretor',
                        to: 'BrokerNew'
                    },
                    {
                        name: 'Corretores',
                        to: 'Broker'
                    }
                ],
                states: [],
                address_error: false,
               settings_error: false,
               enterprise_error: false,
               legal_entity_error: false,
               basic_error: false,
               error_information: false,
               id: null,
               theme: [],
               broker_email_start: ''
           }
       },
       computed:{
         company(){
           return this.$store.getters.getProfile.company;
        },
       },
       methods:{
            verifyDataAvailable(text) {
                let data = {
                    company_id: Cookies.get('company_id'),
                  
                };

                if(text === 'WhatsApp') {
                    data['whatsapp_area_code'] = this.broker.whatsapp_area_code;
                    data['whatsapp'] = this.broker.whatsapp;
                } else if(text === 'Cpf') {
                    data['cpf'] = this.broker.cpf;
                } else {
                    data['email'] = this.broker.email;
                }

                dataAvailable.search(data).then(res => {

                    switch(text) {
                        case 'WhatsApp':
                            this.whatsapp_ok = true;
                            break;
                            case 'Cpf': 
                            this.cpf_ok = true;
                            break;
                            default: 
                            this.email_ok = true;
                            break;
                    }

                }).catch(err => {

                    if(this.broker_email_start != this.broker.email) {
                        this.$bvToast.toast(text + ' Já está sendo utilizado', {
                            title: 'Trocar senha',
                            autoHideDelay: 5000,
                           type: 'danger'
                    });
                    }
                  
                });
            },  
 fetchTheme(){
           
           let data = {
               domain: window.location.hostname
           };

           serviceTheme 
           .search(data)
           .then(resp => {
                
               resp.forEach((element, index) => {
                   switch(element.key){
                        case 'PORTAL_BROKER_BACKGROUND_DESKTOP_LOGGED':
                            this.theme.image_desktop = element.value;
                        break;
                        case 'PORTAL_BROKER_BACKGROUND_MOBILE_LOGGED':
                            this.theme.image_mobile = element.value;
                        break;
                        case 'PORTAL_BROKER_SENTENCE_LOGGED':
                        this.theme.sentence ? this.theme.sentence = element.value[0].toUpperCase() + element.value.substr(1) : ''
                            break;
                       case 'PORTAL_BROKER_BACKGROUND_COLOR':
                        this.theme.color = element.value
                            break;
                           
                        case 'SETTINGS_DOMAIN':
                            this.theme.portal_cliente = element.value
                            break;
                      
                   }
               });
               
           })
          
       },
        setFile(eve) {  
            var reader = new FileReader();
            reader.onload = function (eve2) {              
              this.broker["image"] =  eve2.target.result;
            }.bind(this)          
            reader.readAsDataURL(eve.target.files[0]);           
        },
        setTelephoneIsoCode(value) {
            this.$set(this.broker, 'telephone_area_code_iso', value);
        },
        setWhatsAppIsoCode(value) {
            this.$set(this.broker, 'whatsapp_area_code_iso', value);
        },
        setTelephoneAreaCode(value) {
            this.$set(this.broker, 'telephone_area_code', value);
        },
        setWhatsAppAreaCode(value) {
                this.$set(this.broker, 'whatsapp_area_code', value);
            },
           getAddress(){
              
              if(this.broker.cep.length === 10 || this.broker.cep.length === 8) {
                let url = `https://viacep.com.br/ws/${this.broker.cep.replace('-', '').replace('.', '')}/json`;
    
                    axios.get(url).then(resp =>{
                        this.broker.street = resp.data.logradouro;
                        this.broker.neighborhood = resp.data.bairro;
                        this.broker.state = resp.data.uf;
                        this.broker.city = resp.data.localidade;
                    });
              }
    
       
           },
          
           getCities(uf){
               
               if(!uf)
                    return []
    
               let state = this.states.filter(elem => {
                   return elem.sigla === uf;
               });
               if(!state.length)
                    return state;
                    
               return state[0].cidades;
    
           },

           save(){   

                        
            if (this.broker.password == null || this.broker.password == "") {
                this.broker.password = ""
            }

           service
                .update({...this.broker, 
                    "social_media":  {
                            facebook_url : this.broker.facebook_url,
                            instagram_url : this.broker.instagram_url,
                            linkedin_url : this.broker.linkedin_url,
                            twitter_url : this.broker.twitter_url
                    }, 
                    id: "me"
                })
                .then(resp => {
                    this.$bvToast.toast('Alterações realizadas com sucesso!', {
                        title: 'Corretor atualizado',
                        autoHideDelay: 5000,
                        type: 'success'
                    })
                    this.fetchBroker();
                })
                .catch(err => {
                    this.$bvToast.toast('Cadastro já existe. Utilize outro email, CPF ou Whatsapp', {
                        title: 'Criação do corretor',
                        autoHideDelay: 5000,
                        type: 'danger'
                    })
                })
    
              
           },
           fetchBroker(){
    
                let data = {
                    id: 'me'
                }
    
                service
                .read(data)
                .then(resp => {
                   
                    this.broker = {...resp, 
                        ...resp.broker, 
                        ...resp.broker.social_media, 
                        id: resp.id, created_at: resp.created_at.split('T')[0],
                        is_active: !!+resp.broker.is_active
                    };

                    this.broker_email_start = this.broker.email;
                    console.log(this.broker);
                })
                .catch(err => {
                 
                console.log(err)
                })
    
           },
       },
       mounted(){
        (function(h,o,t,j,a,r){
        h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
        h._hjSettings={hjid:4932490,hjsv:6};
        a=o.getElementsByTagName('head')[0];
        r=o.createElement('script');r.async=1;
        r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
        a.appendChild(r);
     })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
         this.site=window.location.host
           this.states = states;
           this.id = this.$route.params.id;
           this.fetchBroker();
          this.fetchTheme()
       }
    }
    </script>